import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PageRoutes} from "./services/api.service";
import {LoginGuard} from "./guards/login.guard";
import {BrokerGuardGuard} from "./guards/broker-guard.guard";
import {FolderPage} from "./folder/folder.page";

const routes: Routes = [
  // {path: '**', redirectTo: '/dashboard/transactions', pathMatch: 'full'},
  {
    path: '',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
    // canActivate: [LoginGuard],
  },
  {
    path: PageRoutes.login.route,
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: PageRoutes.password.route,
    loadChildren: () => import('./pages/complete-registration/password/password.module').then(m => m.PasswordPageModule),
  },
  {
    path: PageRoutes.password.route + '/:token',
    loadChildren: () => import('./pages/complete-registration/password/password.module').then(m => m.PasswordPageModule),
  },
  {
    path: PageRoutes.phone.route,
    loadChildren: () => import('./pages/complete-registration/phone/phone.module').then(m => m.PhonePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.generic_list.route,
    loadChildren: () => import('./pages/utils/generic-list/generic-list.module').then(m => m.GenericListPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.licenses.route,
    loadChildren: () => import('./pages/complete-registration/licenses/licenses.module').then(m => m.LicensesPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.add_license.route,
    loadChildren: () => import('./pages/complete-registration/add-license/add-license.module').then(m => m.AddLicensePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.message_page.route + '/:type',
    loadChildren: () => import('./pages/common/message-page/message-page.module').then(m => m.MessagePagePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.forgot_password.route,
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
  },
  {
    path: PageRoutes.add_transaction.route + '/:tid',
    loadChildren: () => import('./pages/transaction/add-transaction/add-transaction.module').then(m => m.AddTransactionPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.upload_transaction_file.route + '/:tid/:type',
    loadChildren: () => import('./pages/transaction/upload-transaction-file/upload-transaction-file.module').then(m => m.UploadTransactionFilePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.sale_lease.route + '/:tid',
    loadChildren: () => import('./pages/transaction/sale-lease/sale-lease.module').then(m => m.SaleLeasePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.client_type.route + '/:tid',
    loadChildren: () => import('./pages/transaction/client-type/client-type.module').then(m => m.ClientTypePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.clients.route + '/:tid/:type',
    loadChildren: () => import('./pages/transaction/clients/clients.module').then(m => m.ClientsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.add_client.route + '/:tid/:type',
    loadChildren: () => import('./pages/transaction/add-client/add-client.module').then(m => m.AddClientPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.add_client.route,
    loadChildren: () => import('./pages/transaction/add-client/add-client.module').then(m => m.AddClientPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.add_client.route + '/:cid',
    loadChildren: () => import('./pages/transaction/add-client/add-client.module').then(m => m.AddClientPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.property_type.route + '/:tid',
    loadChildren: () => import('./pages/transaction/property-type/property-type.module').then(m => m.PropertyTypePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.property_more.route + '/:tid/:pid',
    loadChildren: () => import('./pages/transaction/property-more/property-more.module').then(m => m.PropertyMorePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.property_data.route + '/:tid/:pid',
    loadChildren: () => import('./pages/transaction/property-data/property-data.module').then(m => m.PropertyDataPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.property_data.route + '/:pid',
    loadChildren: () => import('./pages/transaction/property-data/property-data.module').then(m => m.PropertyDataPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.property_owner_name.route + '/:tid/:pid',
    loadChildren: () => import('./pages/transaction/property-owner-name/property-owner-name.module').then(m => m.PropertyOwnerNamePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.transaction_referral.route + '/:tid/:pid',
    loadChildren: () => import('./pages/transaction/property-referral/property-referral.module').then(m => m.PropertyReferralPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.transaction_last_data.route + '/:tid/:pid',
    loadChildren: () => import('./pages/transaction/property-last-data/property-last-data.module').then(m => m.PropertyLastDataPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.summary.route + '/:tid',
    loadChildren: () => import('./pages/transaction/summary/summary.module').then(m => m.SummaryPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.properties.route + '/:tid/:cid',
    loadChildren: () => import('./pages/transaction/add-property/add-property.module').then(m => m.AddPropertyPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.properties.route + '/:tid',
    loadChildren: () => import('./pages/transaction/add-property/add-property.module').then(m => m.AddPropertyPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.broker_dashboard.route,
    loadChildren: () => import('./pages/broker/broker-dashboard/broker-dashboard.module').then(m => m.BrokerDashboardPageModule),
    canActivate: [BrokerGuardGuard]
  },
  {
    path: PageRoutes.agents.route,
    loadChildren: () => import('./pages/broker/agents/agents.module').then(m => m.AgentsPageModule),
    canActivate: [BrokerGuardGuard]
  },
  {
    path: PageRoutes.transaction.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/transaction-home/transaction-home.module').then(m => m.TransactionHomePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.client.route + '/:cid',
    loadChildren: () => import('./pages/transaction-overview/transaction-home/transaction-home.module').then(m => m.TransactionHomePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'tab-overview',
    loadChildren: () => import('./pages/transaction-overview/tab-overview/tab-overview.module').then(m => m.TabOverviewPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.add_note.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/add-note/add-note.module').then(m => m.AddNotePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.escrow_deposit.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/escrow-deposit/escrow-deposit.module').then(m => m.EscrowDepositPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.escrow_disburs.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/escrow-disburs/escrow-disburs.module').then(m => m.EscrowDisbursPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.add_disburs.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/add-disburs/add-disburs.module').then(m => m.AddDisbursPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.add_deadline.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/add-deadline/add-deadline.module').then(m => m.AddDeadlinePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.add_deadline_form.route,
    loadChildren: () => import('./pages/transaction-overview/add-deadline-form/add-deadline-form.module').then(m => m.AddDeadlineFormPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.add_deadline_form.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/add-deadline-form/add-deadline-form.module').then(m => m.AddDeadlineFormPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.add_deadline_form.route + '/:tid/:did',
    loadChildren: () => import('./pages/transaction-overview/add-deadline-form/add-deadline-form.module').then(m => m.AddDeadlineFormPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'tab-documents',
    loadChildren: () => import('./pages/transaction-overview/tab-documents/tab-documents.module').then(m => m.TabDocumentsPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.message_client.route + '/:tid/:type',
    loadChildren: () => import('./pages/transaction-overview/message-client/message-client.module').then(m => m.MessageClientPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.message_agent.route + '/:tid/:aid/:type',
    loadChildren: () => import('./pages/transaction-overview/message-client/message-client.module').then(m => m.MessageClientPageModule),
    canActivate: [BrokerGuardGuard]
  },
  {
    path: PageRoutes.message_client_preview.route + '/:tid/:type',
    loadChildren: () => import('./pages/transaction-overview/message-client-preview/message-client-preview.module').then(m => m.MessageClientPreviewPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.cancel_reason.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/cancel-transaction/reason/reason.module').then(m => m.ReasonPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.cancel_notice.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/cancel-transaction/notice/notice.module').then(m => m.NoticePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.cancel_summary.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/cancel-transaction/cancel-summary/cancel-summary.module').then(m => m.CancelSummaryPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.cancellation_disbursement.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/cancel-transaction/cancellation-disbursement/cancellation-disbursement.module').then(m => m.CancellationDisbursementPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.close_files.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/close-transaction/close-files/close-files.module').then(m => m.CloseFilesPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.close_files_upload.route + '/:tid/:type',
    loadChildren: () => import('./pages/transaction-overview/close-transaction/close-files-upload/close-files-upload.module').then(m => m.CloseFilesUploadPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.close_confirm.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/close-transaction/close-confirm/close-confirm.module').then(m => m.CloseConfirmPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.closing_agent.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/close-transaction/closing-agent/closing-agent.module').then(m => m.ClosingAgentPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.close_summary.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/close-transaction/close-summary/close-summary.module').then(m => m.CloseSummaryPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'tab-details',
    loadChildren: () => import('./pages/transaction-overview/tab-details/tab-details.module').then(m => m.TabDetailsPageModule)
  },
  {
    path: PageRoutes.close_amend.route + '/:tid',
    loadChildren: () => import('./pages/transaction-overview/close-transaction/close-amend/close-amend.module').then(m => m.CloseAmendPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.close_amend.route + '/:tid/:forced',
    loadChildren: () => import('./pages/transaction-overview/close-transaction/close-amend/close-amend.module').then(m => m.CloseAmendPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'tab-list',
    loadChildren: () => import('./pages/tab-list/tab-list.module').then(m => m.TabListPageModule)
  },
  {
    path: PageRoutes.offer_more.route + '/:tid/:pid',
    loadChildren: () => import('./pages/transaction/offer-more/offer-more.module').then(m => m.OfferMorePageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.amend_offer_document.route + '/:tid',
    loadChildren: () => import('./pages/amend-offer-document/amend-offer-document.module').then(m => m.AmendOfferDocumentPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.amend_offer_summary.route + '/:tid',
    loadChildren: () => import('./pages/amend-offer-summary/amend-offer-summary.module').then(m => m.AmendOfferSummaryPageModule)
  },
  {
    path: 'tab-calendar',
    loadChildren: () => import('./pages/transaction-overview/tab-calendar/tab-calendar.module').then(m => m.TabCalendarPageModule)
  },
  {
    path: PageRoutes.document_list.route + '/:cid',
    loadChildren: () => import('./pages/document-list/document-list.module').then(m => m.DocumentListPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.document_list.route + '/:cid/:type',
    loadChildren: () => import('./pages/document-list/document-list.module').then(m => m.DocumentListPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'client-list',
    loadChildren: () => import('./pages/tab-list-clients/tab-list-clients.module').then(m => m.TabListClientsPageModule)
  },
  {
    path: PageRoutes.contract_type.route + '/:cid',
    loadChildren: () => import('./pages/listing-search/contract-type/contract-type.module').then(m => m.ContractTypePageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.listing_type.route + '/:cid',
    loadChildren: () => import('./pages/listing-search/listing-type/listing-type.module').then(m => m.ListingTypePageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.listing_type_more.route + '/:cid',
    loadChildren: () => import('./pages/listing-search/listing-type-more/listing-type-more.module').then(m => m.ListingTypeMorePageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.area_budget.route + '/:cid',
    loadChildren: () => import('./pages/listing-search/area-budget/area-budget.module').then(m => m.AreaBudgetPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.listing_details.route + '/:cid',
    loadChildren: () => import('./pages/listing-search/listing-details/listing-details.module').then(m => m.ListingDetailsPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.listing_notes.route + '/:cid',
    loadChildren: () => import('./pages/listing-search/listing-notes/listing-notes.module').then(m => m.ListingNotesPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.agreement_document.route + '/:cid',
    loadChildren: () => import('./pages/client-property/agreement-document/agreement-document.module').then(m => m.AgreementDocumentPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.property_more_info.route + '/:cid',
    loadChildren: () => import('./pages/client-property/more-info/more-info.module').then(m => m.MoreInfoPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.property_last_info.route + '/:cid',
    loadChildren: () => import('./pages/client-property/last-info/last-info.module').then(m => m.LastInfoPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'tab-client-transactions',
    loadChildren: () => import('./pages/tab-client-transactions/tab-client-transactions.module').then(m => m.TabClientTransactionsPageModule)
  },
  {
    path: 'tab-client-properties',
    loadChildren: () => import('./pages/tab-client-properties/tab-client-properties.module').then(m => m.TabClientPropertiesPageModule)
  },
  {
    path: PageRoutes.deadline_detail.route + '/:did',
    loadChildren: () => import('./pages/deadline-detail/deadline-detail.module').then(m => m.DeadlineDetailPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'tab-settings',
    loadChildren: () => import('./pages/tab-settings/tab-settings.module').then(m => m.TabSettingsPageModule)
  },
  {
    path: PageRoutes.upload_escrow_letter.route + '/:tid',
    loadChildren: () => import('./pages/broker/upload-escrow-letter/upload-escrow-letter.module').then(m => m.UploadEscrowLetterPageModule)
    , canActivate: [BrokerGuardGuard]
  },
  {
    path: PageRoutes.upload_letter.route + '/:tid/:type',
    loadChildren: () => import('./pages/broker/upload-letter/upload-letter.module').then(m => m.UploadLetterPageModule)
    , canActivate: [BrokerGuardGuard]
  },
  {
    path: PageRoutes.escrow_letter_summary.route + '/:tid/:type',
    loadChildren: () => import('./pages/broker/escrow-letter-summary/escrow-letter-summary.module').then(m => m.EscrowLetterSummaryPageModule)
    , canActivate: [BrokerGuardGuard]
  },
  {
    path: PageRoutes.disburs_details.route + '/:tid',
    loadChildren: () => import('./pages/broker/disburs-details/disburs-details.module').then(m => m.DisbursDetailsPageModule)
    , canActivate: [BrokerGuardGuard]
  },
  {
    path: PageRoutes.request_actions.route + '/:tid',
    loadChildren: () => import('./pages/broker/request-actions/request-actions.module').then(m => m.RequestActionsPageModule)
    , canActivate: [BrokerGuardGuard]
  },
  {
    path: 'tab-general-calendar',
    loadChildren: () => import('./pages/tab-general-calendar/tab-general-calendar.module').then(m => m.TabGeneralCalendarPageModule)
  },
  {
    path: PageRoutes.upload_agent_files.route + '/:tid',
    loadChildren: () => import('./pages/broker/upload-agent-files/upload-agent-files.module').then(m => m.UploadAgentFilesPageModule)
    , canActivate: [BrokerGuardGuard]
  },
  {
    path: PageRoutes.agent_files_summary.route + '/:tid',
    loadChildren: () => import('./pages/broker/agent-files-summary/agent-files-summary.module').then(m => m.AgentFilesSummaryPageModule)
    , canActivate: [BrokerGuardGuard]
  },
  {
    path: PageRoutes.lead_details.route,
    loadChildren: () => import('./pages/broker/lead-details/lead-details.module').then(m => m.LeadDetailsPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.lead_details.route + '/:cid',
    loadChildren: () => import('./pages/broker/lead-details/lead-details.module').then(m => m.LeadDetailsPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.client_to_agent_files.route + '/:mid',
    loadChildren: () => import('./pages/client-to-agent-files/client-to-agent-files.module').then(m => m.ClientToAgentFilesPageModule)
  },
  {
    path: PageRoutes.note_detail.route + '/:nid',
    loadChildren: () => import('./pages/overview-detail/notes/notes.module').then(m => m.NotesPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.deposit_detail.route + '/:did',
    loadChildren: () => import('./pages/overview-detail/deposit-detail/deposit-detail.module').then(m => m.DepositDetailPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.disburs_detail.route + '/:did',
    loadChildren: () => import('./pages/overview-detail/disburs-detail/disburs-detail.module').then(m => m.DisbursDetailPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.message_to_client_detail.route + '/:mid/:type',
    loadChildren: () => import('./pages/overview-detail/message-to-client-detail/message-to-client-detail.module').then(m => m.MessageToClientDetailPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.listing_detail.route + '/:lid',
    loadChildren: () => import('./pages/overview-detail/listing-detail/listing-detail.module').then(m => m.ListingDetailPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.property_detail.route + '/:pid',
    loadChildren: () => import('./pages/property-detail/property-detail.module').then(m => m.PropertyDetailPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'google-calendar',
    loadChildren: () => import('./pages/google-calendar/google-calendar.module').then(m => m.GoogleCalendarPageModule)
  },
  {
    path: PageRoutes.notifications.route,
    loadChildren: () => import('./pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.notification_detail.route + '/:nid',
    loadChildren: () => import('./pages/notification-detail/notification-detail.module').then(m => m.NotificationDetailPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'create-agent',
    loadChildren: () => import('./pages/broker/create-agent/create-agent.module').then(m => m.CreateAgentPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'create-agent/:aid',
    loadChildren: () => import('./pages/broker/create-agent/create-agent.module').then(m => m.CreateAgentPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.agent_detail.route + '/:aid',
    loadChildren: () => import('./pages/agent-detail/agent-detail.module').then(m => m.AgentDetailPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'choose-agent/:aid/:cid',
    loadChildren: () => import('./pages/choose-agent/choose-agent.module').then(m => m.ChooseAgentPageModule)
    , canActivate: [BrokerGuardGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'security',
    loadChildren: () => import('./pages/security/security.module').then(m => m.SecurityPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'notifications-settings',
    loadChildren: () => import('./pages/notifications-settings/notifications-settings.module').then(m => m.NotificationsSettingsPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'edit-property/:pid',
    loadChildren: () => import('./pages/edit-property/edit-property.module').then(m => m.EditPropertyPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.deadline_search.route,
    loadChildren: () => import('./pages/search-deadline/search-deadline.module').then(m => m.SearchDeadlinePageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.user_data.route,
    loadChildren: () => import('./pages/complete-registration/user-data/user-data.module').then(m => m.UserDataPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'fake-registration',
    loadChildren: () => import('./pages/fake-registration/fake-registration.module').then(m => m.FakeRegistrationPageModule)
  },
  {
    path: PageRoutes.broker_alert.route,
    loadChildren: () => import('./pages/broker-alert/broker-alert.module').then(m => m.BrokerAlertPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.broker_alert.route + '/:uid',
    loadChildren: () => import('./pages/broker-alert/broker-alert.module').then(m => m.BrokerAlertPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.start_new_transaction.route,
    loadChildren: () => import('./pages/start-new-transaction/start-new-transaction.module').then(m => m.StartNewTransactionPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.search_property_type.route + '/:uid',
    loadChildren: () => import('./pages/search-property-type/search-property-type.module').then(m => m.SearchPropertyTypePageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.search_specs.route + '/:uid',
    loadChildren: () => import('./pages/search-specs/search-specs.module').then(m => m.SearchSpecsPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.search_display_type.route + '/:uid',
    loadChildren: () => import('./pages/search-result-display-type/search-result-display-type.module').then(m => m.SearchResultDisplayTypePageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.search_results.route + '/:uid',
    loadChildren: () => import('./pages/search-results/search-results.module').then(m => m.SearchResultsPageModule)
  },
  {
    path: PageRoutes.search_property_detail.route + '/:key',
    loadChildren: () => import('./pages/search-property-detail/search-property-detail.module').then(m => m.SearchPropertyDetailPageModule)
  },
  {
    path: PageRoutes.search_end.route + '/:uid',
    loadChildren: () => import('./pages/search-end/search-end.module').then(m => m.SearchEndPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'send-search-to-client/:uid',
    loadChildren: () => import('./pages/send-search-to-client/send-search-to-client.module').then(m => m.SendSearchToClientPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.search_refine.route + '/:uid',
    // path: 'search-refine',
    loadChildren: () => import('./pages/search-refine/search-refine.module').then(m => m.SearchRefinePageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'sedi',
    loadChildren: () => import('./pages/sedi/sedi.module').then(m => m.SediPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'sede',
    loadChildren: () => import('./pages/sede/sede.module').then(m => m.SedePageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'sede/:uid',
    loadChildren: () => import('./pages/sede/sede.module').then(m => m.SedePageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'tiers',
    loadChildren: () => import('./pages/tiers/tiers.module').then(m => m.TiersPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'tier/:uid',
    loadChildren: () => import('./pages/tier/tier.module').then(m => m.TierPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'tier',
    loadChildren: () => import('./pages/tier/tier.module').then(m => m.TierPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'new_devs',
    loadChildren: () => import('./pages/new-devs/new-devs.module').then(m => m.NewDevsPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'new_dev',
    loadChildren: () => import('./pages/new-dev/new-dev.module').then(m => m.NewDevPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'new_dev/:uid',
    loadChildren: () => import('./pages/new-dev/new-dev.module').then(m => m.NewDevPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'new_dev_files/:uid',
    loadChildren: () => import('./pages/new-dev-files/new-dev-files.module').then(m => m.NewDevFilesPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'pre-listings',
    loadChildren: () => import('./pages/pre-listings/pre-listings.module').then(m => m.PreListingsPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'pre-listings/:mine',
    loadChildren: () => import('./pages/pre-listings/pre-listings.module').then(m => m.PreListingsPageModule)
    , canActivate: [LoginGuard]
  },
  // {
  //   path: 'pre-listing-categories',
  //   loadChildren: () => import('./pages/add-pre-listing/pre-listing-categories/pre-listing-categories.module').then( m => m.PreListingCategoriesPageModule)
  // },
  {
    path: 'pre-listing-categories/:uid',
    loadChildren: () => import('./pages/add-pre-listing/pre-listing-categories/pre-listing-categories.module').then(m => m.PreListingCategoriesPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'pre-listing-data/:uid',
    loadChildren: () => import('./pages/add-pre-listing/pre-listing-data/pre-listing-data.module').then(m => m.PreListingDataPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: 'pre-listing-detail/:uid',
    loadChildren: () => import('./pages/pre-listing-detail/pre-listing-detail.module').then( m => m.PreListingDetailPageModule)
    , canActivate: [LoginGuard]
  },
  {
    path: PageRoutes.personal_website.route + '/:aid',
    loadChildren: () =>
      import('./pages/personal-website/personal-website.module').then(
        (m) => m.PersonalWebsiteModule
      ),
    canActivate: [LoginGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
